<template>
  <div>
    <router-link class="come-back" to="/admin/apparatus">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование документа' : 'Создание документа' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddDocuments" ref="AddDocuments">
        <div class="content-sb">
          <p>Название документа<span>*</span></p>
          <input required placeholder="Введите название документа" type="text" v-model="form.title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Название документа (Англ)</p>
          <input placeholder="Введите название документа (Англ)" type="text" v-model="form.title_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Аппарат</p>
          <select required  ref="apparat" v-model="form.apparat" class="input blue" name="convocation">
            <option v-if="$route.query.type === 'create'" disabled selected>Выберите категорию</option>
            <option v-for="item in $store.getters.getApparatusCategory"
                    :selected="form.apparat === item.id"
                    :key="`getApparatusCategory${item.id}`" :value="item.id">
              {{ item.title }}
            </option>
          </select>
        </div>

        <div class="content-sb">
          <p>Порядок<span>*</span></p>
          <input required placeholder="Введите порядок документа" type="number" v-model="form.order" class="input blue">
        </div>

        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Старый документ<span>*</span></p>
          <a target="_blank" :href="form.link">ссылка</a>
        </div>
        <div class="content-sb">
          <p>Документ<span>*</span></p>
          <input :required="$route.query.type === 'edit' ? false: true"  type="file" name="file"  class="input blue">
        </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/apparatus" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createUser',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailApparatusDocuments : {
        title: '',
        title_eng: '',
        order: 0,
        apparat: 0
      }
    }
  },
  methods: {
    AddDocuments () {
      const formData = new FormData(this.$refs.AddDocuments)
      formData.append('title', this.form.title)
      formData.append('order', this.form.order)
      formData.append('apparat', this.form.apparat)
      // formData.append('apparat', this.$refs.apparat.value)
      if (this.form.title_eng) formData.append('title_eng', this.form.title_eng)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editApparatusDocuments', { id: this.form.id, data: formData })
      } else {
        this.$store.dispatch('createApparatusDocuments', formData)
      }
    }
  },
  mounted () {
    this.$store.dispatch('setApparatusCategory')
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailApparatusDocuments === null) {
      this.$router.push('/admin/apparatus')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
